import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { redirectIfNotLoggedIn, redirectIfLoggedIn, redirect404, getUpdatedUser, redirectIfNotInternal, redirectIfInternal, redirectIfMaintenance, redirectIfNotMaintenance, setDefaults } from './guards';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login'),
        beforeEnter: multiguard([redirectIfLoggedIn, setDefaults])
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/performance/live-vs-replay',
        name: 'performance-live-vs-replay',
        component: () => import('@/views/performance/LiveVsReplay'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/operations/trades',
        name: 'operations/trades',
        component: () => import('@/views/operations/Trades'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/operations/reconciliation',
        name: 'operations-reconciliation',
        component: () => import('@/views/operations/Reconciliation'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/operations/executions',
        name: 'operations-executions',
        component: () => import('@/views/operations/Executions'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/operations/audit',
        name: 'operations-audit',
        component: () => import('@/views/operations/Audit'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/performance/tolerance',
        name: 'performance-tolerance',
        component: () => import('@/views/performance/Tolerance'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    // {
    //     path: '/risk/exposure',
    //     name: 'risk-exposure',
    //     component: () => import('@/views/risk/Exposure'),
    //     beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    // },
    {
        path: '/performance/missed-trades',
        name: 'performance-missed-trades',
        component: () => import('@/views/performance/MissedTrades'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/trades/strategy-index',
        name: 'trades-strategy-index',
        component: () => import('@/views/trades/StrategyIndex'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    // {
    //     path: '/operations/position-summary',
    //     name: 'operations-position-summary',
    //     component: () => import('@/views/operations/PositionSummary'),
    //     beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    // },
    {
        path: '/performance/execution-tolerance',
        name: 'performance-execution-tolerance',
        component: () => import('@/views/performance/ExecutionTolerance'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/performance/account-summary',
        name: 'performance-account-summary',
        component: () => import('@/views/performance/AccountSummary'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/risk/leverages',
        name: 'risk-leverages',
        component: () => import('@/views/risk/Leverages'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/administration/maintenance',
        name: 'administration-maintenance',
        component: () => import('@/views/administration/Maintenance'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfNotInternal, setDefaults])
    },
    {
        path: '/administration/manageusers',
        name: 'administration-manage-users',
        component: () => import('@/views/administration/ManageUsers'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfNotInternal, setDefaults])
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/views/Maintenance'),
        beforeEnter: multiguard([redirectIfInternal, redirectIfNotMaintenance, setDefaults])
    },
    {
        path: '/operations/volume-summary',
        name: 'operations-volume-summary',
        component: () => import('@/views/operations/VolumeSummary'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/operations/reason-summary',
        name: 'operations-reason-summary',
        component: () => import('@/views/operations/ReasonSummary'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/live-chart',
        name: 'live-chart',
        component: () => import('@/views/Chart'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/performance/breakdowns',
        name: 'performance-breakdowns',
        component: () => import('@/views/performance/Breakdowns'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/performance/hourly-returns',
        name: 'performance-hourly-returns',
        component: () => import('@/views/performance/HourlyTradeReturn'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/operations/platform-speed',
        name: 'operations-platform-speed',
        component: () => import('@/views/operations/PlatformSpeed'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/performance/performance-statistics',
        name: 'performance-performance-statistics',
        component: () => import('@/views/performance/PerformanceStatistics'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/operations/platform-timing',
        name: 'operations-platform-timing',
        component: () => import('@/views/operations/PlatformTiming'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/performance/trade-period-stats',
        name: 'trade-period-stats',
        component: () => import('@/views/performance/TradePeriodStats'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '/administration/all-charts',
        name: 'administration-all-charts',
        component: () => import('@/views/administration/AllCharts'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'fees-breakdown',
        path: '/performance/fees-breakdown',
        component: () => import('@/views/performance/FeesBreakdown'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'return-and-win-rate',
        path: '/performance/return-and-win-rate',
        component: () => import('@/views/performance/ReturnAndWinRate'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'report-creation',
        path: '/administration/report-creation',
        component: () => import('@/views/administration/ReportCreation'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'system-health',
        path: '/system-health',
        component: () => import('@/views/operations/SystemHealth'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'optimiser-comparison-progress',
        path: '/operations/optimiser-comparison-progress',
        component: () => import('@/views/performance/OptimiserProgress'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'optimiser-progress',
        path: '/operations/optimiser-progress',
        component: () => import('@/views/operations/OptimiserProgress'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'traded-minutes',
        path: '/performance/traded-minutes',
        component: () => import('@/views/performance/TradedMinutes'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'random-sample',
        path: '/performance/random-sample',
        component: () => import('@/views/performance/RandomSample'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'cached-stats',
        path: '/operations/cached-stats',
        component: () => import('@/views/operations/CacheStats'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'trade-prediction',
        path: '/operations/trade-prediction',
        component: () => import('@/views/operations/TradePrediction'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'platform-speed-report',
        path: '/operations/platform-speed-report',
        component: () => import('@/views/operations/PlatformSpeedReport'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'operations-portfolio-tolerance',
        path: '/operations/portfolio-tolerance',
        component: () => import('@/views/operations/PortfolioTolerance'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        name: 'performance-breakdown-win-rates',
        path: '/performance/breakdown-win-rates',
        component: () => import('@/views/performance/BreakdownWinRates'),
        beforeEnter: multiguard([redirectIfNotLoggedIn, redirectIfMaintenance, setDefaults])
    },
    {
        path: '*',
        beforeEnter: multiguard([redirect404, setDefaults])
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.afterEach(getUpdatedUser);

export default router;
