import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import socket from './socket';
import { BootstrapVue } from 'bootstrap-vue';
import '@/assets/scss/main.scss';
import '@mdi/font/scss/materialdesignicons.scss';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import { numberFormat } from 'highcharts';
import { format } from 'date-fns';
import Notifications from 'vue-notification';
import VueNumerals from 'vue-numerals';
import VTooltip from 'v-tooltip';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(HighchartsVue);
Vue.use(Notifications);
Vue.use(VueNumerals);
Vue.use(VTooltip);

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
})

Vue.prototype.$ws = socket;

Vue.filter('number', function(value, decimals = 2, multiply = false) {
    if(decimals === null) {
        decimals = -1;
    }

    let parsedValue = parseFloat(value);
    if(multiply) {
        parsedValue = parseFloat(parsedValue) * 100;
    }
    // console.log("input:  " + value)
    // console.log("output: " + parsedValue)
    return numberFormat(parsedValue, decimals, '.', ',');
});

Vue.filter('percentage', function(value) {
    return value + '%';
});

Vue.filter('currency', function(value, useBrackets = false, symbolOnly = false) {
    let activeCurrency = store.state.activeCurrency;

    let parsedValue = parseFloat(value);
    let absolute = parsedValue < 0 ? true : false;

    if(activeCurrency === 'GBP') {
        if(symbolOnly){return "£"}
        if(absolute) {
            if(useBrackets) {
                return '(£' + value.toString().substring(1) + ')';
            } else {
                return '-£' + value.toString().substring(1);
            }
        } else {
            return '£' + value;
        }
    } else if(activeCurrency === 'USD') {
        if(symbolOnly){return "$"}
        if(absolute) {
            if(useBrackets) {
                return '($' + value.toString().substring(1) + ')';
            } else {
                return '-$' + value.toString().substring(1);
            }
        } else {
            return '$' + value;
        }
    } else {
        return value;
    }
});

Vue.filter('date', function(value, formatString) {
    return format(value, formatString);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
