import store from '@/store';
import router from '@/router';

export function getUser() {
    let sessionUser = sessionStorage.getItem('user');
    if(sessionUser) {
        return JSON.parse(sessionUser);
    }

    let localUser = localStorage.getItem('user');
    if(localUser) {
        return JSON.parse(localUser);
    }

    return null;
}

export function getActiveAccount() {
    let sessionActiveAccount = sessionStorage.getItem('activeAccount');
    if(sessionActiveAccount) {
        return sessionActiveAccount;
    }

    let localActiveAccount = localStorage.getItem('activeAccount');
    if(localActiveAccount) {
        return localActiveAccount;
    }

    return null;
}

export function getToken() {
    let sessionToken = sessionStorage.getItem('token');
    if(sessionToken) {
        return sessionToken;
    }

    let localToken = localStorage.getItem('token');
    if(localToken) {
        return localToken;
    }

    return null;
}

export function setToken(token, remember) {
    if(remember) {
        localStorage.setItem('token', token);
    } else {
        sessionStorage.setItem('token', token);
    }

    store.commit('updateToken', token);
}

export function setUser(user, remember = null) {
    if(remember === null) {
        let oldLocalUser = localStorage.getItem('user');
        if(oldLocalUser) {
            remember = true;
        } else {
            remember = false;
        }
    }

    if(remember) {
        localStorage.setItem('user', JSON.stringify(user));
    } else {
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    store.commit('updateUser', user);
}

export function logout(redirect = true) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');

    if(redirect) {
        router.push({name: 'login'}).catch(err => {});
    }
}

export function convertToEST(localDate, offset = -4){
    //console.log(offset)
    let localTime = localDate.getTime();
    let localOffset = new Date().getTimezoneOffset() * 60000;
    let utc = localTime + localOffset; //Corodinate Universal Time
    let estTime = utc + (3600000*offset);
    return new Date(estTime);
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function resolveDay(dayString){
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday","Thursday", "Friday", "Saturday"]
    let date = new Date(dayString)
    return days[date.getDay()]
}

export function getAccountSummaryRange(maxNum){
    let rangeNums = [2,5,10,20,50,70,100]
    for(let num of rangeNums){
        if(maxNum < num){
            return num;
        }
    }
}