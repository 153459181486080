import Vue from 'vue';
import { getToken, getUser, logout, setUser } from '@/lib/Helpers';
import { getOldPermalink } from '@/lib/PageLookup';
import router from '@/router';
import store from '@/store';

export function redirectIfNotLoggedIn(to, from, next) {
    let token = getToken();
    let user = getUser();

    if(token === null || user === null) {
        logout(false);
        next({name: 'login'});
    } else {
        next();
    }
}

export function redirectIfLoggedIn(to, from, next) {
    let token = getToken();
    let user = getUser();

    if(token !== null && (user !== null && user.hasOwnProperty('email'))) {
        next({name: 'dashboard'});
    } else {
        logout(false);
        next();
    }
}

export function redirect404(to, from , next) {
    let token = getToken();
    let user = getUser();

    if(token === null || user === null) {
        logout(false);
        next({name: 'login'});
    } else {
        next({name: 'dashboard'});
    }
}

export function redirectIfNotInternal(to, from, next) {
    if(!store.state.auth.user.internal) {
        next({name: 'dashboard'});
    } else {
        next();
    }
}

export function redirectIfInternal(to, from, next) {
    if(store.state.auth.user.internal) {
        next({name: 'dashboard'});
    } else {
        next();
    }
}

function updateOnMaintenance(event) {
    store.commit('updateMaintenance', event.response);

    if(!store.state.auth.user.internal && event.response.maintenance) {
        router.push({name: 'maintenance'});
    }
}

export function redirectIfMaintenance(to, from, next) {
    Vue.prototype.$ws.once('maintenance', updateOnMaintenance);
    Vue.prototype.$ws.send({
        accountName: store.state.activeAccount,
        request: 'Maintenance',
        args: {}
    });

    next();
}

function updateOffMaintenance(event) {
    store.commit('updateMaintenance', event.response);

    if(!event.response.maintenance) {
        router.push({name: 'dashboard'});
    }
}

export function redirectIfNotMaintenance(to, from, next) {
    Vue.prototype.$ws.once('maintenance', updateOffMaintenance);
    Vue.prototype.$ws.send({
        accountName: store.state.activeAccount,
        request: 'Maintenance',
        args: {}
    });

    next();
}

function updateUser(event) {
    setUser(event.response.user, null);

    let route = router.currentRoute;
    if(typeof route !== 'undefined' && route !== null) {
        let permalink = getOldPermalink(route.path);

        let pages = store.state.auth.user.allowedPages;

        if(pages.indexOf(permalink) === -1) {
            let dashboardPermalink = getOldPermalink('dashboard');

            if(pages.indexOf(dashboardPermalink) === -1) {
                logout();

                // Vue.prototype.$notify({
                //     group: 'primary',
                //     text: 'Your session has expired, please login again.',
                //     type: 'error'
                // });
            } else {
                router.push({name: 'dashboard'});
            }
        }
    }
}

export function getUpdatedUser(to, from) {
    let token = getToken();
    if(token) {
        Vue.prototype.$ws.once('login', updateUser);
        Vue.prototype.$ws.send({
            accountName: null,
            request: 'Login',
            args: {
                token: token
            }
        });
    }
}

export function setDefaults(to, from, next) {
    Vue.prototype.$ws.close();
    next();
}