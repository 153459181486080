import EventEmitter from "events";
import { isMatch, isPlainObject, merge } from "lodash";
import { logout, getToken } from "@/lib/Helpers";
import Vue from "vue";
import { differenceInMilliseconds } from "date-fns";

class WebSocketClient extends EventEmitter {
  setDefaults(url = null) {
    if (url !== null) {
      this.url = url;
    }

    this.socket = null;
    this.reconnectionAttempts = 0;

    this.messageQueue = [];

    this.expectingRequests = {};

    this.clearLastUpdates();
  }

  constructor(url, options = {}) {
    super();

    this.setDefaults(url, options);

    //this.on('error', (error) => {});
  }

  clearLastUpdates() {
      this.lastUpdates = {};
  }

  open() {
    this.socket = new WebSocket(this.url);
    this.socket.onopen = (event) => {
      this.emit("connected", this, event);

      if (this.reconnectionAttempts > 0) {
        this.emit("reconnected", this, event);
      }

      this.reconnectionAttempts = 0;

      for (let message of this.messageQueue) {
        this.send(message, true);
      }
    };

    this.socket.onclose = (event) => {
      this.emit("close", this, event);

      this.setDefaults();

      this.reconnectionAttempts++;
      this.open(true);
    };

    this.socket.onerror = (error) => {
      //this.emit('error', this, error);
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      event.json = data;
      event.request = data;
      event.response = JSON.parse(data.response);

      delete event.request.response;

      if (this.expectingRequests.hasOwnProperty(event.request.request)) {
        for (let index in this.expectingRequests[event.request.request]) {
          if (
            isMatch(
              this.expectingRequests[event.request.request][index],
              event.request
            )
          ) {
            this.expectingRequests[event.request.request].splice(index, 1);
            break;
          }
        }
      }

      if (
        isPlainObject(event.response) &&
        event.response.hasOwnProperty("errors") &&
        event.response.errors.hasOwnProperty("token")
      ) {
        if (
          event.request.request !== "Login" ||
          (event.request.request === "Login" &&
            event.request.args.hasOwnProperty("token"))
        ) {
          // Vue.prototype.$notify({
          //     group: 'primary',
          //     text: 'Your session has expired, please login again.',
          //     type: 'error'
          // });

          logout();

          return;
        }
      }

    //   if(event.request.request !== 'SearchSymbols') {
    //     if (this.lastUpdates.hasOwnProperty(event.request.request) && event.request.request != "TradeDetailsPrices") {
    //         var now = new Date();
    
    //         if (
    //             differenceInMilliseconds(now, this.lastUpdates[event.request.request]) <= 400
    //         ) {
    //           return;
    //         }
    //       }
    //   }

      this.lastUpdates[event.request.request] = new Date();

      this.emit(event.request.request.toLowerCase(), event);
    };

    return this;
  }

  close() {
    if (this.socket !== null) {
      this.socket.close();
    }
  }

  send(message, force = false) {
    if (typeof message !== "string") {
      let defaultMessage = {
        userID: 0,
        dashboardID: 0,
        pageID: 0,
        componentID: 0,
        args: {},
      };

      if (message.request !== "Login") {
        defaultMessage.args.token = getToken();
      }

      message = merge(defaultMessage, message);

      // if(!force && message.request !== 'Login') {
      //     try {
      //         if(!this.expectingRequests.hasOwnProperty(message.request)) {
      //             this.expectingRequests[message.request] = [message];
      //         } else {
      //             for(let request of this.expectingRequests[message.request]) {
      //                 if(isMatch(request, message)) {
      //                     return;
      //                 }
      //             }

      //             this.expectingRequests[message.request].push(message);
      //         }
      //     } catch(error) {}
      // }
    }

    if (this.socket === null || this.socket.readyState !== WebSocket.OPEN) {
      this.messageQueue.push(message);
      return;
    }

    if (typeof message !== "string") {
      this.socket.send(JSON.stringify(message));
    } else {
      this.socket.send(message);
    }
  }

  clearExpectingRequests() {
    this.expectingRequests = {};
  }
}

export default WebSocketClient;
