<template>
    <div id="app" :class="{'sidebar-collapsed': $store.state.sidebarCollapsed, 'sidebar-not-collapsed': !$store.state.sidebarCollapsed}">
        <router-view/>
        <notifications group="primary" position="bottom right"></notifications>
    </div>
</template>

<script>
export default {
    beforeMount() {
        this.$ws.on('optimiserstatus', this.onOptimiserStatus);
    },
    beforeDestroy() {
        this.$ws.off('optimiserstatus', this.onOptimiserStatus);
    },
    updated(){
        //set current page
        this.$store.commit("setActivePage",this.$route.name)
    },
    methods: {
        onOptimiserStatus(event) {
            console.log(event);
        }
    }
}
</script>