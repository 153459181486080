import WebSocketClient from "@/lib/WebSocketClient";
import store from "@/store";

let ws = new WebSocketClient(
  "wss://analytics.savernakecapital.com:8091/Request"
  // "ws://localhost:8090/Request"
).open();

ws.on("maintenance", function(event) {
  store.commit("updateMaintenance", event.response);
});

export default ws;
