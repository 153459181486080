let pageLookup = {
    'dashboard': 'dashboard',

    // Performance
    'performance/breakdown': 'performance/breakdowns',
    'performance/live-vs-replay': 'trades/live-vs-replay',

    // Trades
    'operations/trades': 'trades/trades',
    'operations/reconciliation' : 'trades/reconciliation',
    'operations/executions': 'trades/executions',
    'performance/tolerance': 'trades/tolerance',
    'risk/exposure': 'trades/exposure',
    'performance/missed-trades': 'trades/missing-orders',
    'trades/strategy-index': 'trades/strategy-index',
    'performance/execution-tolerance': 'trades/execution-tolerance',
    'operations/chart':'operations/chart',

    // Summaries
    'operations/performance-summary': 'performance/performance-summary',
    'operations/position-summary': 'trades/position-summary',
    'operations/hft-summary': 'hft',
    'performance/account-summary': 'account-summary',
    'summary/monthly': 'monthly-process',
    'operations/volume-summary': 'trades/volume',

    // Month-end
    'fact-sheet': 'fact-sheet',
    'month-end/client-invoice': 'account-fees',
    'risk/leverages': 'month-end/leverages',

    // Administrator
    'administration/maintenance': 'admin',

    'operations/reason-summary': 'summary/reason',
    'operations/platform-speed': 'operations/platform-speed'
};

export function parsePermalink(permalink) {
    if(permalink.startsWith('/')) {
        return permalink.substring(1);
    }

    return permalink;
}

export function getOldPermalink(permalink) {
    let parsedPermalink = parsePermalink(permalink);

    if(pageLookup.hasOwnProperty(parsedPermalink)) {
        return pageLookup[parsedPermalink];
    }

    return parsedPermalink;
}