import Vue from 'vue';
import Vuex from 'vuex';
import { getToken, getUser } from '@/lib/Helpers';

Vue.use(Vuex);

let activeAccount = localStorage.getItem('activeAccount');
if(activeAccount === null) {
    activeAccount = 'New Track Record';
}

let activeCurrency = localStorage.getItem('activeCurrency');
if(activeCurrency === null) {
    activeCurrency = 'USD';
}

let user = getUser();
if(user === null) {
    user = {
        firstName: '',
        lastName: '',
        email: '',
        allowedPages: [],
        allowedAccounts: []
    };
}

let sidebarCollapsed = false;
var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
if(viewportWidth < 830) {
    sidebarCollapsed = true;
}

let restartedAccounts = localStorage.getItem('restartedAccounts');
if(restartedAccounts === null) {
    restartedAccounts = [];
} else {
    restartedAccounts = JSON.parse(restartedAccounts);
}

export default new Vuex.Store({
    state: {
        auth: {
            token: getToken(),
            user: user
        },
        activeAccount: activeAccount,
        activeCurrency: activeCurrency,
        activePage: null,
        sidebarCollapsed: sidebarCollapsed,
        maintenance: {
            enabled: false,
            reason: ''
        },
        restartedAccounts: restartedAccounts,
        tradingView: {
            waitingForSymbolsResolve: {},
            waitingForSymbolsSearch: {},
            waitingForBars: {},
            selectedTrade: {},
            firstOnly:true,
            subscriptions: {}
        },
        charts: [],
    },
    mutations: {
        updateActiveAccount(state, value) {
            state.activeAccount = value;
            localStorage.setItem('activeAccount', value);
        },
        updateActiveCurrency(state, value) {
            state.activeCurrency = value;
            localStorage.setItem('activeCurrency', value);
        },
        updateToken(state, token) {
            state.auth.token = token;
        },
        updateUser(state, user) {
            state.auth.user = Object.assign({}, user);
        },
        updateSidebarCollapse(state) {
            state.sidebarCollapsed = !state.sidebarCollapsed;
        },
        updateMaintenance(state, data) {
            state.maintenance.enabled = data.maintenance;
            state.maintenance.reason = data.reason;
        },
        addRestartedAccount(state, account) {
            if(state.restartedAccounts.indexOf(account) === -1) {
                state.restartedAccounts.push(account);
                localStorage.setItem('restartedAccounts', JSON.stringify(state.restartedAccounts));
            }
        },
        removeRestartedAccount(state, index) {
            state.restartedAccounts.splice(index, 1);
            localStorage.setItem('restartedAccounts', JSON.stringify(state.restartedAccounts));
        },
        addResolvingSymbol(state, data) {
            state.tradingView.waitingForSymbolsResolve[data.symbol] = {
                onResolve: data.onResolve,
                onError: data.onError
            };
        },
        addSearchingSymbol(state, data) {
            state.tradingView.waitingForSymbolsSearch[data.symbol] = data.callback;
        },
        addWaitingForBars(state, data) {
            state.tradingView.waitingForBars[data.symbol] = {
                onResult: data.onResult,
                onError: data.onError
            };
        },
        setSelectedTrade(state, trade) {
            state.tradingView.selectedTrade = trade;
        },
        addToCharts(state, data){
            state.charts[data.chartID] = data.chart;
        },
        setFirstOnly(state, toggle){
            state.tradingView.selectedTrade.firstOnly = toggle
        },
        setActivePage(state, page){
            state.activePage = page;
        },
        addSubscription(state, data) {
            state.tradingView.subscriptions[data.symbol] = data.callback;
        }
    },
    actions: {},
    modules: {},
    getters: {
        getCharts:(state)=> () =>{
            return state.charts
        },
        getAccount: (state) => (name) => {
            for(let account of state.auth.user.allowedAccounts) {
                if(account.name === name) {
                    return account;
                }
            }

            return null;
        },
        isFxAccount: (state, getters) => (name = null, account = null) => {
            if(name === null) {
                name = state.activeAccount;
            }

            let foundAccount = account !== null ? account : getters.getAccount(name);

            if(foundAccount === null) {
                return false;
            }

            return foundAccount.type === 0;
        },
        isFuturesAccount: (state, getters) => (name = null, account = null) => {
            if(name === null) {
                name = state.activeAccount;
            }

            let foundAccount = account !== null ? account : getters.getAccount(name);

            if(foundAccount === null) {
                return false;
            }

            return foundAccount.type === 1;
        },
        isCombinedAccount: (state, getters) => (name = null, account = null) => {
            if(name === null) {
                name = state.activeAccount;
            }

            let foundAccount = account !== null ? account : getters.getAccount(name);

            if(foundAccount === null) {
                return false;
            }

            return foundAccount.type === 2;
        },
        isHftAccount: (state, getters) => (name = null, account = null) => {
            if(name === null) {
                name = state.activeAccount;
            }

            let foundAccount = account !== null ? account : getters.getAccount(name);

            if(foundAccount === null) {
                return false;
            }

            return foundAccount.type === 3;
        }
    }
});
